import "./MoodJournal.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "../../component/Button/Button";
import partnerLogo from "../../assets/img/partner.svg";
import mood from "../../assets/img/mood.svg";
import psycho from "../../assets/img/psycho.svg";

const MoodJournal = ({ }) => {
	return (
		<div className="userWrapper">
			<Container>
			   <h2>Mood Journal</h2>
				<div className="assesment-container">
					<div className="main-info">
						<p>
						Journaling your thoughts, emotions and challenges has been shown to reduce anxiety and depression.
						</p>
						<img src={mood} />
					</div>
					<div className="bottom-btn-group">
					<Link className="w-100" to="/journal-entry">
					  <Button name="Add journal entry"/>
					</Link>
					<Link to="/journal-entry-prev" className="w-100">
					  <Button name="View previous journal entries" />
					</Link>
					<Link to="/help-crisis" className="w-100">
					<button className="red-border-btn">
						<span>In a mental health crisis?</span>
						<img src={psycho} />
					</button>
					</Link>
					</div>
				</div>
			</Container>
			<div className="partnerShip">
				<p>In partnership with</p>
				<img src={partnerLogo} />
			</div>
		</div>
	);
};

export default MoodJournal;
