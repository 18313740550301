//import PageHeader from '../../../src/components/common/PageHeader'
import "./Register.scss";
import Form from "react-bootstrap/Form";
import Button from "../../component/Button/Button";
import BorderButton from "../../component/BorderButton/BorderButton";
import partnerLogo from "../../assets/img/partner.svg";
import question from "../../assets/img/question.svg";
import { Link, useNavigate } from "react-router-dom";
import { Formik, useFormik, Form as FormikForm, ErrorMessage } from "formik";
import { SignUpSchema } from "../../helper/Validation";
import { userAxios } from "../../utils/config";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { DataStore } from "../../store";
import Loader from "../../component/Loader/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const Register = ({}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [version, setVersion] = useState();
  // console.log("Env cred==>", process.env.REACT_APP_AUTH0_CLIENT_ID);
  const [years, setYears] = useState([]);
  const navigate = useNavigate();
  const { dataState } = DataStore();
  // console.log("DDatata  state", dataState);
  const { dataDispatch, versionDispatch } = DataStore();

  const userId = sessionStorage.getItem("authUserId");
  const maxOffset = 122;

  const fetchVersions = async () => {
    try {
      const response = await userAxios.get(`/version`);
      // console.log("Version==>", response);
      setVersion(response.data.version);
      versionDispatch({ type: 1, payload: response.data.version });
    } catch (error) {
      console.log("error", error);
    }
  };
  const HandleSubmit = async (values) => {
    const userValues = { ...values };
    console.log("vALUES==>", userValues);
    const date = `${values.day}/${values.month}/${values.year}`;
    delete userValues.day;
    delete userValues.month;
    delete userValues.year;
    delete userValues.terms;
    userValues.dob = date;
    // console.log("data register-->", userValues);
    const dob = userValues.dob;
    const email = userValues.email.toLowerCase();
    const insuranceNumber = userValues.insuranceNumber;
    const termsTwo = userValues.termsTwo;
    console.log("vALUES==>", email);

    try {
      const response = await userAxios.post(
        `/checkinsurance`,
        // {userValues}
        { dob, email, insuranceNumber, termsTwo }
      );
      // console.log("ressss==>", response);
      dataDispatch({ type: 1, payload: response.data });

      navigate("/register/verify-code");
      setLoading(false);
    } catch (error) {
      // console.log("Error=>", error.response);
      console.log("error message=>", error);
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  const handleError = (e) => {
    if (e["insuranceNumber"]) {
      document.getElementById("one").scrollIntoView();
    } else if (e["day"] || e["month"] || e["year"]) {
      document.getElementById("two").scrollIntoView();
    } else if (e["email"] || e["terms"] || e["termsTwo"]) {
      document.getElementById("three").scrollIntoView();
    }
  };

  function openWidgetArticle(articleId) {
    window.FreshworksWidget("open", "article", { id: articleId });
  }

  useEffect(() => {
    if (userId) {
      navigate("/dashboard");
    }

    // fetchVersions();
    var thisYear = new Date().getFullYear();
    let abc = [...years];
    for (var i = 0; i <= maxOffset; i++) {
      var year = thisYear - i;
      abc.push(year);
    }
    setYears([...abc]);
  }, []);
  // let ab = new Date("2022-10-17T09:46:50.753Z");

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="userWrapper">
          <div className="firstStep">
            <h2>Create account</h2>
            <p>Please create an account to access your services.</p>

            {/* {console.log("Email==>", data?.email)} */}
            <Formik
              enableReinitialize={true}
              initialValues={{
                day: "",
                month: "",
                year: "",
                email: "",
                insuranceNumber: "",
                terms: false,
                termsTwo: false,
              }}
              onSubmit={HandleSubmit}
              validationSchema={SignUpSchema}
            >
              {({
                values,
                handleBlur,
                handleChange,
                setFieldValue,
                validateForm,
              }) => (
                <FormikForm>
                  {/* <form onSubmit={formik.handleSubmit}> */}
                  <div className="formContainer">
                    <Form.Group
                      className="formMargin"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        <span id="one">Insurance policy number</span>
                        <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter your 7-digit insurance policy number located on your policy documentation.</Tooltip>}
                    >
                        <span>
                          <img src={question} />
                        </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Insurance policy number"
                        name="insuranceNumber"
                        value={values.insuranceNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      <div className="global-font-body text-danger">
                        <ErrorMessage name="insuranceNumber" />
                      </div>
                    </Form.Group>
                    <div className="form-label label" id="two">
                      <span>Date of birth</span>
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Input your date of birth in DD/MM/YYYY format.</Tooltip>}
                    >
                      <span>
                        <img src={question} />
                      </span>
                    </OverlayTrigger>
                    </div>
                    <Form.Group
                      className="formMargin"
                      controlId="formBasicPassword"
                    >
                      <div className="row">
                        <div className="col-4">
                          <Form.Label className="font-16">Day</Form.Label>
                          <Form.Select
                            type="text"
                            className="form-control"
                            placeholder="DD"
                            name="day"
                            value={values.day}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">DD</option>
                            {[
                              "01",
                              "02",
                              "03",
                              "04",
                              "05",
                              "06",
                              "07",
                              "08",
                              "09",
                              "10",
                              "11",
                              "12",
                              "13",
                              "14",
                              "15",
                              "16",
                              "17",
                              "18",
                              "19",
                              "20",
                              "21",
                              "22",
                              "23",
                              "24",
                              "25",
                              "26",
                              "27",
                              "28",
                              "29",
                              "30",
                              "31",
                            ].map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="global-font-body text-danger">
                            <ErrorMessage name="day" />
                          </div>
                        </div>
                        <div className="col-4">
                          <Form.Label className="font-16">Month</Form.Label>
                          <Form.Select
                            type="text"
                            placeholder="MM"
                            name="month"
                            className="form-control"
                            value={values.month}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">MM</option>
                            {[
                              "01",
                              "02",
                              "03",
                              "04",
                              "05",
                              "06",
                              "07",
                              "08",
                              "09",
                              "10",
                              "11",
                              "12",
                            ].map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="global-font-body text-danger">
                            <ErrorMessage name="month" />
                          </div>
                        </div>
                        <div className="col-4">
                          <Form.Label className="font-16">Year</Form.Label>
                          <Form.Select
                            type="text"
                            placeholder="YYYY"
                            className="form-control"
                            name="year"
                            value={values.year}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value="">YYYY</option>
                            {years.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="global-font-body text-danger">
                            <ErrorMessage name="year" />
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="formMargin"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        <span id="three">Email</span>
                        <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter your email address to register.</Tooltip>}
                    >
                        <span>
                          <img src={question} />
                        </span>
                    </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="global-font-body text-danger">
                        <ErrorMessage name="email" />
                      </div>
                    </Form.Group>
                  </div>
                  {/* </form> */}
                  <div className="check-row mb-0" id="four">
                    {/* <div className="check-row"> */}
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checbox_terms"
                        value={values.terms}
                        name="terms"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="checbox_terms"></label>
                    </div>
                    <div className="check-label">
                      I agree to Medipartner{" "}
                      <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000501523)}>Terms & Conditions</a>
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="check-row  global-font-body text-danger">
                    <ErrorMessage name="terms" />
                  </div>

                  <div className="check-row mb-0">
                    {/* <div className="check-row"> */}
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="checbox_policy"
                        value={values.termsTwo}
                        name="termsTwo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <label htmlFor="checbox_policy"></label>
                    </div>
                    <div className="check-label">
                      I consent to the data processing described in Medipartners{" "}
                      <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000501523)}>Terms & Conditions</a> and{" "}
                      <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000501525)}>Privacy Policy</a>. Medipartner uses the
                      personal data collected here to facilitate service
                      provision in accordance with our{" "}
                      <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000501525)}>Privacy Policy</a>.
                    </div>
                    {/* </div> */}
                  </div>
                  <div className="check-row  global-font-body text-danger">
                    <ErrorMessage name="termsTwo" />
                  </div>

                  <Button
                    name="Create account"
                    type="submit"
                    onClick={() => validateForm().then((e) => handleError(e))}
                  />
                </FormikForm>
              )}
            </Formik>
            <div className="NoAccount">Already a user?</div>
            {/* <Link to={"/"}> */}
            <BorderButton name="Sign in" onClick={() => navigate("/")} />
            {/* </Link> */}
            <div className="partnerShip">
              <p>In partnership with</p>
              <img src={partnerLogo} />
            </div>
          </div>

          {/* step 2 */}

          {/* <div className="twoStep">
				<h2>Thank you for signing up, Daryn!</h2>
				<p>Please enter the code received on your email.</p>
				<Form className="formContainer">
					<div className="form-label label">
						<span>Verification code</span>
						<span>
							<img src={question} />
						</span>
					</div>
					<Form.Group className="formMargin" controlId="formBasicPassword">
						<div className="row">
							<div className="col-2">
								<Form.Control type="text" />
							</div>
							<div className="col-2">
								<Form.Control type="text" />
							</div>
							<div className="col-2">
								<Form.Control type="text" />
							</div>
							<div className="col-2">
								<Form.Control type="text" />
							</div>
							<div className="col-2">
								<Form.Control type="text" />
							</div>
							<div className="col-2">
								<Form.Control type="text" />
							</div>
						</div>
					</Form.Group>
				</Form>
				<Button name="Confirm" />
				<div className="partnerShip">
					<p>In partnership with</p>
					<img src={partnerLogo} />
				</div>
			</div> */}

          {/* Step 3 */}
          {/* <div className="threeStep">
        <h2>Just a few last steps to get you onboard!</h2>
        <p>Please set a password for access to your account.</p>
        <Form className="formContainer">
          <Form.Group className="formMargin" controlId="formBasicPassword">
            <Form.Label>
              <span>Password</span>
              <span>
                <img src={question} />
              </span>
            </Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group
            className="formMargin"
            controlId="formBasicConfirmPassword"
          >
            <Form.Label>
              <span>Confirm Password</span>
              <span>
                <img src={question} />
              </span>
            </Form.Label>
            <Form.Control type="password" placeholder="Confirm Password" />
          </Form.Group>
        </Form>
        <Button name="Create account" />
        <div className="partnerShip">
          <p>In partnership with</p>
          <img src={partnerLogo} />
        </div>
      </div> */}
        </div>
      )}
    </>
  );
};

export default Register;
