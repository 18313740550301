const CryptoJS = require("crypto-js");

// Retrieve the encryption key from process.env or provide a default value
const encryptionKey = "BritishSeniors";

// Encrypt a response using the encryption key
exports.encryptResponse = (response, accessToken) => {
  if (accessToken) {
    // console.log(accessToken);

    const saltkey = encryptionKey + accessToken;

    const caesarShift = 7; // Define the Caesar shift value

    // Apply Caesar cipher to the saltkey
    let encryptedSaltKey = "";
    for (let i = 0; i < saltkey.length; i++) {
      const charCode = saltkey.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedSaltKey += String.fromCharCode(
          ((charCode - 65 + caesarShift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedSaltKey += String.fromCharCode(
          ((charCode - 97 + caesarShift) % 26) + 97
        );
      } else {
        encryptedSaltKey += saltkey.charAt(i); // Non-alphabetic characters remain the same
      }
    }

    const encryptedResponse = CryptoJS.AES.encrypt(
      JSON.stringify(response),
      encryptedSaltKey
    ).toString();
    // console.log(encryptedSaltKey);
    // console.log(encryptedResponse);
    return encryptedResponse;
  }
};

// Decrypt a response using the encryption key
exports.decryptResponse = (encryptedResponse, accessToken) => {
  const saltkey = encryptionKey + accessToken;

  const caesarShift = 7; // Define the Caesar shift value

  // Apply Caesar cipher to the saltkey
  let encryptedSaltKey = "";
  for (let i = 0; i < saltkey.length; i++) {
    const charCode = saltkey.charCodeAt(i);
    if (charCode >= 65 && charCode <= 90) {
      // Uppercase letters
      encryptedSaltKey += String.fromCharCode(
        ((charCode - 65 + caesarShift) % 26) + 65
      );
    } else if (charCode >= 97 && charCode <= 122) {
      // Lowercase letters
      encryptedSaltKey += String.fromCharCode(
        ((charCode - 97 + caesarShift) % 26) + 97
      );
    } else {
      encryptedSaltKey += saltkey.charAt(i); // Non-alphabetic characters remain the same
    }
  }
  // console.log(accessToken);
  const decryptedBytes = CryptoJS.AES.decrypt(
    encryptedResponse,
    encryptedSaltKey
  );
  const decryptedResponse = JSON.parse(
    decryptedBytes.toString(CryptoJS.enc.Utf8)
  );
  return decryptedResponse;
};
