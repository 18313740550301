import React, { useEffect, useState } from "react";
import "./ConfirmChampion.scss";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import BorderButton from "../../../component/BorderButton/BorderButton";
import Button from "../../../component/Button/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setNurseMessage } from "../../../Redux/Reducers/NurseChampion";
import { userAxios } from "../../../utils/config";
import Loader from "../../../component/Loader/Loader";
import partnerLogo from "../../../assets/img/partner.svg";

import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/config/axiosInstance";

const ConfirmChampion = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const [edit, setEdit] = useState({ address: false, email: false });
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    // address: "1 Wollaton Street, Nottingham NG15FS",
    // streetAddress: "",
    // locality: "",
    // region: "",
    // country: "",
    // postalCode: "",
    contactNumber: "",
    email: "",
    date: [],
    tnc: false,
    consent: false,
  });
  const date = new Date();
  const arr = [],
    arr1 = [];
  const newDate = date.toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
  });
  arr.push(date);
  arr1.push(newDate);
  for (let i = 1; i < 7; i++) {
    const nextDay = new Date(arr[i - 1]);
    nextDay.setDate(arr[i - 1].getDate() + 1);
    const nextDate = nextDay.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });
    arr.push(nextDay);
    arr1.push(nextDate);
  }

  const handleSubmit = async () => {
  //  return console.log("formdata ==>", formData, userData);
  let fromMail = formData.email;
  let localeDate = formData.date;
  // let localeDate = formData.date.toString();
  const obj = { ...formData };
    // const addressObj = {
    //   streetAddress: formData.streetAddress,
    //   locality: formData.locality,
    //   region: formData.region,
    //   country: formData.country,
    //   postalCode: formData.postalCode,
    // };
    if (formData?.contactNumber.length != 11) {
      toast.error("Please enter a valid contact number")
      return;
    }
    const customerData = {
      name: `${userData?.given_name} ${userData?.family_name}`,
      dob: userData?.user_metadata?.birthdate,
      // phonenumber: userData?.user_metadata?.phone_number,
      phonenumber: formData?.contactNumber,
      availability: localeDate,
      termsAccepted: "Yes",
      privacyAccepted: "Yes",
    };
    // return console.log("formdata ==>", customerData);
    delete obj.address;
    delete obj.tnc;
    delete obj.consent;
    delete obj.postalCode;
    delete obj.region;
    delete obj.country;
    delete obj.locality;
    delete obj.streetAddress;
    delete obj.contactNumber;
    if (userId.startsWith("auth0|")) {
      userId = userId.slice(6);
    }
    obj.userId = userId;
    obj.createdAt = new Date().toISOString();
    obj.details = {
      contactNumber: formData?.contactNumber,
      // address: { ...addressObj },
    };

    // console.log("Obj from create request==>", obj);
    // console.log("Obj from  customer data==>", customerData);
    try {
      const token = sessionStorage.getItem("accessToken");

      if (token)
        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + token;

          // return console.log("Obj from create request==>", obj);
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/nursechampion`,
        {
          ...obj,
        }
      );
      // console.log("Mood journal response", response);
      // console.log(response.status);
      if (response.status == 200) {
        dispatch(setNurseMessage({ nurseMessage: true, date: new Date().toISOString() }));
        toast.success(response.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("Mood journal error", error);
    }
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/sendmail`,
        {
          customerData,
          fromMail,
        }
      );

      // console.log("Mood journal response", response);
      if (response) {
        navigate("/request-champion");
      }
    } catch (error) {
      console.log("Mood journal error", error);
    }

    window.scrollTo(0, 0);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      // console.log("response on create request ==>", response);
      const addressData = JSON.parse(
        response?.data?.userInfo?.user_metadata?.address
      );
      let contactNumber = response?.data?.userInfo?.user_metadata?.phone_number;
      if (contactNumber && !contactNumber.startsWith("0")) {
        contactNumber = "0" + contactNumber;
      }
      setFormData({
        ...formData,
        // streetAddress: addressData?.street_address,
        // locality: addressData?.region,
        // region: addressData?.region,
        // country: addressData?.country,
        // postalCode: addressData?.postal_code,
        contactNumber: contactNumber,
        email: response?.data?.userInfo?.email,
      });
      setLoading(false);
    } catch (error) {
      console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function openWidgetArticle(articleId) {
    window.FreshworksWidget("open", "article", { id: articleId });
  }

  const handleCheckboxChange = (item) => {
    // Check if the item is already in the formData.date array
    const isChecked = formData.date.includes(item);
  
    // If it's checked and present in the array, remove it, otherwise add it
    if (isChecked) {
      setFormData({
        ...formData,
        date: formData.date.filter((dateItem) => dateItem !== item),
      });
    } else {
      setFormData({
        ...formData,
        date: [...formData.date, item],
      });
    }
  };
  

  function validateInput(inputValue) {
    // Define a regular expression pattern for 11 digits
    const regexPattern = /^\d{11}$/;

    // Test if the inputValue matches the pattern
    return regexPattern.test(inputValue);
  }

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    
    // If the input value is empty or matches the 11-digit pattern, or if it's shorter than 11 digits
  if (inputValue === '' || validateInput(inputValue) || inputValue.length < 11) {
    setFormData({
      ...formData,
      contactNumber: inputValue,
    });
    }
  };

  function validateContactNumber(contactNumber) {
    const regexPattern = /^0\d{10}$/;
    return regexPattern.test(contactNumber);
  }
  
  // Function to handle the button click
  function handleButtonClick() {
    const contactNumber = formData.contactNumber;
  if (!edit.contactNumber) {
    setError(false)
   return setEdit({ ...edit, contactNumber: !edit.contactNumber });
  }
    if (validateContactNumber(contactNumber)) {
      setError(false)
      setEdit({ ...edit, contactNumber: !edit.contactNumber });
    } else {
      setError(true)
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="userWrapper">
          <div className="commonWrapper edit-detail">
            <Link to="/request-champion">
              <h3>
                <img className="img-fluid me-2" src={FaAngleLeft} />
                Confirm details
              </h3>
            </Link>
            <hr />
            <p className="mt-8">
              Please review the details below and submit your request to receive
              telephone support from your qualified nurse champion.
            </p>
            <div className="text-start mainData">
              <p>
                <strong>
                  {userData?.given_name} {userData?.family_name}
                </strong>
              </p>

              {!edit?.contactNumber && formData?.contactNumber}
              {edit?.contactNumber ? (
                <div className="formContainer mt-3 p-0 ms-0">
                  <Form.Group controlId="formBasicConfirmPassword" className="mb-0">
  <Form.Label>Phone Number</Form.Label>
  <Form.Control
    className={`formMargin ${error ? "mb-2" : ""}`}
    type="text"
    value={formData?.contactNumber}
    onChange={handleContactNumberChange}
  />
  {error && <small className="error-message">Please enter a valid phone number with 0 prefix.</small>}
</Form.Group>
                </div>
              ) : (
                <p>
              </p>
              )}
              <BorderButton
                name={edit.contactNumber ? "Update" : "Edit"}
                onClick={handleButtonClick}
              />
              <hr />
            </div>
            <div className="text-start mainData">
              <p>
                <strong>You will be contacted at this email:</strong>
              </p>
              {edit?.email ? (
                <div className="formContainer mt-3 p-0 ms-0">
                  <Form.Group
                    className="formMargin"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Control
                      type="email"
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
              ) : (
                <p>{formData?.email}</p>
              )}

              <BorderButton
                name={edit.email ? "Update" : "Edit"}
                onClick={() => {
                  // console.log(
                  //   " /^(([^<>()[]\\.,;:s@",
                  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  //     formData.email
                  //   ),
                  //   formData.email
                  // );
                  if (formData?.email) {
                    if (
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        formData.email
                      )
                    ) {
                      setEdit({ ...edit, email: !edit.email });
                    } else {
                      toast.error("please fill valid email");
                    }
                  } else {
                    toast.error("please fill the email");
                  }
                }}
              />
              <hr />
            </div>
            {/* Dates hidden */}
            {/* <div className="text-start mainData">
              <p className="mb-2">
                <strong>Choose dates you’re available</strong>
              </p>
              <p>
                Your Nurse Champion will schedule your call using these days
              </p>

              <div className="multiSelect">
              {arr1.map((item, index) => (
    <label key={index} className={`custom-checkbox ${formData.date.includes(item) ? 'selected' : ''}`}>
      <input
        type="checkbox"
        value={item}
        checked={formData.date.includes(item)}
        onChange={(e) => handleCheckboxChange(item)}
      />
      <span>
        {index === 0
          ? "Today"
          : index === 1
          ? "Tomorrow"
          : item}
      </span>
    </label>
  ))}
</div>

              <hr />
            </div> */}
            <div className="d-flex mb-4">
              <Form.Check
                type="checkbox"
                id="terms"
                onChange={(e) =>
                  setFormData({ ...formData, tnc: e.target.checked })
                }
              />
              <span htmlFor="terms" className="ms-3">
                I agree to CP Nursings <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000370309)}>Terms & Conditions</a>
              </span>
            </div>
            <div className="d-flex mb-5">
              <Form.Check
                type="checkbox"
                id="privacy"
                onChange={(e) =>
                  setFormData({ ...formData, consent: e.target.checked })
                }
              />
              <span htmlFor="privacy" className="ms-3">
                I consent to the data processing described in CP Nursings{" "}
                <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000370309)}>Terms & Conditions</a> and{" "}
                <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000365827)}>Privacy Policy</a>. CP Nursing uses the
                personal data collected here to facilitate service provision in
                accordance with our <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000365827)}>Privacy Policy</a>.
              </span>
            </div>
            <Button
              disabled={
                !formData?.tnc || !formData?.consent 
              }
              // disabled={
              //   !formData?.tnc || !formData?.consent || !formData?.date.length
              // }
              name="Submit request"
              type="submit"
              onClick={handleSubmit}
            />
          </div>

          <div className="partnerShip">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmChampion;
