import Register from "../container/Register/Register";
import Login from "../container/login/login";
import Account from "../container/account/account";
import PersonalDetail from "../container/account/Personal-details/personal-detail";
import Dashboard from "../container/dashboard/dashboard";
import Security from "../container/account/security/security";
import NewPassword from "../container/account/security/new-password/new-password";
import About from "../container/account/about/about";
import Documents from "../container/account/documents/documents";
import HealthAssesment from "../container/HealthAssesment/HealthAssesment/HealthAssesment";
import HealthAssesmentSteps from "../container/HealthAssesment/HealthAssesmentSteps/HealthAssesmentSteps";
import RequestChampion from "../container/NurseChampion/RequestChampion/RequestChampion";
import ConfirmChampion from "../container/NurseChampion/ConfirmChampion/ConfirmChampion";
import RequestList from "../container/NurseChampion/RequestList/RequestList";
import SymptomChecker from "../container/SymptomChecker/SymptomChecker";
import MoodJournal from "../container/MoodJournal/MoodJournal";
import Remote from "../container/remote/Remote";
import JournalEntry from "../container/MoodJournal/JournalEntry/JournalEntry";
import JournalEnteriesPrev from "../container/MoodJournal/JournalEnteriesPrev/JournalEnteriesPrev";
import HelpCrisis from "../container/MoodJournal/HelpCrisis/HelpCrisis";

import VerifyCode from "../container/Register/verify-code";
import CreatePassword from "../container/Register/CreatePassword";
import Authenticate from "../component/Authenticate";
import SingleEntryPrev from "../container/MoodJournal/JournalEnteriesPrev/SingleEntryPrev";
import Singlerequest from "../container/NurseChampion/RequestList/SingleRequest";
import ForgotPassword from "../container/ForgotPassword/ForgotPassword";
import Terms from "../component/Terms/Terms";
import Legal from "../component/Legal/Legal";
import Cookies from "../component/Cookies/Cookies";
import Verify from "../container/account/verify";

const allRoutes = [
  // {
  //   path: "/",
  //   component: Login,
  // },
  // {
  //   path: "/forgot-password",
  //   component: ForgotPassword,
  // },
  // {
  //   path: "/register",
  //   component: Register,
  // },
  // {
  //   path: "/register/verify-code",
  //   component: VerifyCode,
  // },
  // {
  //   path: "/register/create-password",
  //   component: CreatePassword,
  // },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/account/about",
    component: About,
  },
  {
    path: "/account/documents",
    component: Documents,
  },
  {
    path: "/account/personal-detail",
    component: PersonalDetail,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/account/security",
    component: Security,
  },
  {
    path: "/account/security/new-password",
    component: NewPassword,
  },
  {
    path: "/account/security/new-password/verify-otp",
    component: Verify,
  },
  {
    path: "/health-assesment",
    component: HealthAssesment,
  },
  {
    path: "/health-assesment-steps",
    component: HealthAssesmentSteps,
  },
  {
    path: "/request-champion",
    component: RequestChampion,
  },
  {
    path: "/confirm-champion",
    component: ConfirmChampion,
  },
  {
    path: "/request-list",
    component: RequestList,
  },
  {
    path: "/single-request",
    component: Singlerequest,
  },
  {
    path: "/remote-physio",
    component: Remote,
  },
  {
    path: "/mood-journal",
    component: MoodJournal,
  },
  {
    path: "/journal-entry",
    component: JournalEntry,
  },
  {
    path: "/journal-entry-prev",
    component: JournalEnteriesPrev,
  },
  {
    path: "/single-journal-entry",
    component: SingleEntryPrev,
  },
  {
    path: "/help-crisis",
    component: HelpCrisis,
  },
  {
    path: "/privacy-policy",
    component: Legal,
  },
  {
    path: "/terms-conditions",
    component: Terms,
  },
  {
    path: "/cookie-policy",
    component: Cookies,
  },
  // {
  //   path: "/authenticate",
  //   component: Authenticate,
  // },
];

export default allRoutes;
