import "../SymptomChecker/SymptomChecker.scss";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import { userAxios } from "../../utils/config";
import { axiosInstance } from "../../utils/config/axiosInstance";

// import Iframe from "react-iframe";
const Remote = ({}) => {
  const [iframes, setIframes] = useState("");
  const userToken = sessionStorage.getItem("accessToken");
  const userId = sessionStorage.getItem("authUserId");
  const navigate = useNavigate();

  // <script>function openWidgetArticle() {FreshworksWidget('open', 'article', { id: 123 });}window.fwSettings = { 'widget_id':47000000016};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() </script><script type='text/javascript' src='https://widget.freshworks.com/widgets/47000000016.js' async defer></script>

  // const insertScriptFreshWidget = (
  //   <Helmet>
  //     <script>
  //       {`function openWidgetArticle() {FreshworksWidget('open', 'article', { id: 123 });}window.fwSettings = { 'widget_id':47000000016};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
  //     </script>
  //   </Helmet>
  // );
  // console.log("insertScriptFreshWidget", insertScriptFreshWidget);
  const fetchData = async () => {
    // let userData = JSON.stringify(sessionStorage.getItem());
    let response;
    try {
      response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    } catch (error) {}
    // console.log("response--->", response);
    // http://localhost:3264/api/v1/phio/getaccess
    // http://54.201.160.69:3264/api/v1/phio/getaccess
    try {
      const responseIframe = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/phio/getaccess`,
        {
          customer_name: "Medipartner",
          member: {
            first_name: response?.data?.userInfo?.given_name,
            last_name: response?.data?.userInfo?.family_name,
            dob: "1990-08-24",
            email: "daryn@medipartner.co.uk",
            phone: response?.data?.userInfo?.user_metadata.phone_number,
            member_id: "1234567891",
            f2f_allowance: 0,
            membership_start_date: "2022-11-05",
            membership_end_date: "2023-11-04",
            address_line_1: ".",
            address_line_2: ".",
            address_line_3: ".",
            postcode: "DA6 8DS",
            policy_holder_name: "British Seniors",
          },
        }
      );
      // setIframes(responseIframe?.data?.data?.data?.access_url);
      window.open(responseIframe?.data?.data?.data?.access_url);
      // console.log("responseIframe", responseIframe);
      navigate("/dashboard");
    } catch (error) {
      console.log("error-->", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("iframe", iframes);
  return (
    <div className="symptom-checker">
      {/* <div className="symptom-checker_blueBar">
        <Container>
          <span>111 Online</span>
        </Container>
      </div>
      <div className="symptom-checker_section">
        <Container>
          <div className="symptom-checker_section_inner">
            <h2>Get medical help</h2>
            <p>111 online can tell you</p>
            <ul>
              <li>
                where to get help for your symptoms, if you're not sure what to
                do
              </li>
              <li>how to find general health information and advice</li>
              <li>
                where to get an emergency supply of your prescribed medicine
              </li>
              <li>how to get a repeat prescription</li>
            </ul>
            <button className="btn start-btn">Start</button>
            <hr />
            <div className="bottom-info">
              <h2>111 callback times</h2>
              <p>
                The 111 callback service might be busy.{" "}
                <Link>Get information about 111 callbacks.</Link>
              </p>
            </div>
          </div>
        </Container>
        <div className="symptom-checker_bottom-info">
          <Container>
            <p>
              Always follow any medical advice you've already been given by your
              doctor. See our <Link>terms</Link>
            </p>
            <Link>
              Other ways to contact 111 if you have a hearing problem or need
              help in other.
            </Link>
          </Container>
        </div>
      </div> */}
      <Container>
        <iframe
          src={iframes}
          width={"100%"}
          style={{ overflow: "scroll", minHeight: "160vh" }}
          //   scrolling="no"
        ></iframe>
        {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> */}
      </Container>
    </div>
  );
};

export default Remote;
