import "./RequestList.scss";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { setID, setRequests } from "../../../Redux/Reducers/NurseChampion";
import { addDays, subDays } from "date-fns";
import DatePicker from "react-datepicker";
import Loader from "../../../component/Loader/Loader";
import { userAxios } from "../../../utils/config";
import partnerLogo from "../../../assets/img/partner.svg";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/config/axiosInstance";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const RequestList = ({}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [arr, setArr] = useState([]);
  let userId = sessionStorage.getItem("authUserId");
  let newUserId;
  if (userId.startsWith("auth0|")) {
    newUserId = userId.slice(6);
  }
  const userToken = sessionStorage.getItem("accessToken");
  const onChange = (dates) => {
    const [start, end] = dates;
    // console.log(start, end);
    setStartDate(start);
    // console.log("dateeeeeeeeee===>>>", new Date(start));
    setEndDate(end);
    if (start && end) {
      const sdate = start.toLocaleDateString("en-GB");
      const edate = end && end.toLocaleDateString("en-GB");
      setShow(false);
      fetchFilterRequests(sdate, edate);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      // console.log("response", response?.data?.userInfo);
      setLoading(false);
    } catch (error) {
      console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };

  const fetchFilterRequests = async (startdate, enddate) => {
    // console.log(startdate, "start==================>>");
    // console.log(enddate, "start==================>>");
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL_DEVELOPMENT
        }/api/v1/filterRequests?id=${newUserId}&fromDate=${
          startdate && startdate
        }&toDate=${enddate && enddate}`
      );
      // console.log("Mood filter list response", response.data);
      response.data.requests.forEach((element) => {
        const date = new Date(element.createdAt);
        element.newDate = date.toLocaleDateString("en-US", {
          dateStyle: "medium",
        });
      });
      response.data.requests.forEach((element, index) => {
        if (element?.createdAt) {
          const days = subDays(new Date(element?.createdAt), 0);
          arr[index] = days;
        }
      });
      setData(response.data.requests);
      dispatch(setRequests(response.data.requests));
      setLoading(false);
      // toast.success(`Entries are showing from ${startdate} to ${enddate}`);
    } catch (error) {
      console.log("Mood journal list error", error);
      setLoading(false);
    }
  };

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/getNursechampion?id=${newUserId}`
      );
      // console.log("Mood journal list response", response);
      response.data.forEach((element) => {
        const date = new Date(element.createdAt);
        element.newDate = date.toLocaleDateString("en-US", {
          dateStyle: "medium",
        });
      });
      response.data.forEach((element, index) => {
        if (element?.createdAt) {
          // subDays(new Date(), 7),
          //             addDays(new Date(), 7),
          const days = subDays(new Date(element?.createdAt), 0);
          // console.log("dayss-----ss>", days);
          arr[index] = days;
        }
      });
      setData(response.data);
      dispatch(setRequests(response.data));
      setLoading(false);
    } catch (error) {
      console.log("Mood journal list error", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRequests();
    getData();
  }, []);

  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) { // 27 represents the escape key
        setShow(false);
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="commonWrapper">
          <div className="d-flex justify-content-between">
            <Link to="/request-champion">
              <h3>
                <img className="img-fluid me-2" src={FaAngleLeft} />
                Requests
              </h3>
            </Link>
            <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Filters</Tooltip>}
                    >
            <div className="circle-btn" onClick={() => setShow(!show)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 21V15H13V17H21V19H13V21H11ZM3 19V17H9V19H3ZM7 15V13H3V11H7V9H9V15H7ZM11 13V11H21V13H11ZM15 9V3H17V5H21V7H17V9H15ZM3 7V5H13V7H3Z"
                  fill="#3484C9"
                />
              </svg>
            </div>
            </OverlayTrigger>
            {/* {console.log("arrr--->", show)} */}
            {/* {console.log("start--date--->", startDate)} */}
            {/* {console.log("end--date--->", endDate)} */}
            {/* {console.log("arrrrrrrrrrrrrr--ddd->", subDays(new Date(), 7))} */}
            {show && (
              <div ref={datePickerRef}>
                
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                highlightDates={[
                  {
                    "react-datepicker__day--highlighted-custom-x": [...arr],
                  },
                ]}
                selectsRange
                inline
              />
              </div>
            )}
          </div>
          {startDate && endDate
            ? `Entries are showing from ${startDate?.toLocaleDateString(
                "en-US",
                {
                  dateStyle: "medium",
                }
              )} to ${endDate?.toLocaleDateString("en-US", {
                dateStyle: "medium",
              })}`
            : ""}
          <hr />

          <ul className="accountSub">
            {data.length != 0 ? (
              data?.map((item, index) => (
                <li key={index}>
                  <Link
                    to="/single-request"
                    onClick={() => {
                      dispatch(setID(index), window.scrollTo(0, 0));
                    }}
                  >
                    <div>
                      <p>Submitted on {item?.newDate} </p>
                      <small>
                        by {userData?.given_name} {userData?.family_name}
                      </small>
                    </div>
                    <FaAngleRight />
                  </Link>
                </li>
              ))
            ) : (
              <div>No data found</div>
            )}
          </ul>
          <div className="text-center partnerWith">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default RequestList;
