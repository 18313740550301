import "./RequestChampion.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "../../../component/Button/Button";
import partnerLogo from "../../../assets/img/partner.svg";
import healthIcons from "../../../assets/img/health-icons.svg";
import timer from "../../../assets/img/timer.svg";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { axiosInstance } from "../../../utils/config/axiosInstance";

const RequestChampion = ({}) => {
  const message = useSelector((store) => store?.nurseChampion);
  var date = new Date(message?.date);
  const newDate = date.toLocaleDateString("en-US", { dateStyle: "medium" });
  const [data, setData] = useState([]);
  let userId = sessionStorage.getItem("authUserId");
  let newUserId;
  if (userId.startsWith("auth0|")) {
    newUserId = userId.slice(6);
  }
  let dates = new Date().toLocaleDateString();
  // console.log("dates", dates);
  const fetchFilterRequests = async () => {
    // console.log(
    //   "Api fetcher==>",
    //   `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/get=${newUserId}`
    // );
    // setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/getNursechampion?id=${newUserId}`
      );
      // console.log("Mood journal list response", response);
      // response.forEach((element) => {
      //   const date = new Date(element.createdAt);
      //   element.newDate = date.toLocaleDateString("en-US", {
      //     dateStyle: "medium",
      //   });
      // });
      // response.forEach((element, index) => {
      //   if (element?.createdAt) {
      //     const days = subDays(new Date(element?.createdAt), 0);
      //     arr[index] = days;
      //   }
      // });
      setData(response.data);
      // dispatch(setRequests(response));
      // setLoading(false);
    } catch (error) {
      console.log("Mood journal list error", error);
      // setLoading(false);
    }
  };
  // console.log(
  //   "dddddddd",
  //   new Date(data[data.length - 1]?.createdAt).toLocaleDateString()
  // );
  useEffect(() => {
    fetchFilterRequests();
  }, []);
  return (
    <div className="userWrapper">
      <h2>Request Nurse Champion support</h2>
      <Container>
        <div className="assesment-container">
          <div className="main-info">
            <p>
              A member of our nursing team will help you navigate your health &
              wellbeing journey with personalised advocacy, guidance, and
              support.
            </p>
            <img src={healthIcons} />
          </div>
          {new Date(data[0]?.createdAt).toLocaleDateString() !=
          dates ? (
            <Link to="/confirm-champion" className="mb-32 d-block">
              <Button
                name="Create request"
                // disabled={
                //   new Date(
                //     data[data.length - 1]?.createdAt
                //   ).toLocaleDateString() == dates
                //     ? true
                //     : false
                // }
              />
            </Link>
          ) : (
            <div className="mb-4">
              <Button name="Create request" disabled />
            </div>
          )}
          {new Date(data[0]?.createdAt).toLocaleDateString() ==
            dates && (
            <div className="submit-text">
              Request submitted {dates} . Please wait 24 hours to submit another
              request.
            </div>
          )}
          <Link to="/request-list">
            <Button name="View previous requests" />
          </Link>
        </div>
      </Container>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img src={partnerLogo} />
      </div>
    </div>
  );
};

export default RequestChampion;
