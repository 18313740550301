import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import Loader from "../../../component/Loader/Loader";
import { setEntries, setID } from "../../../Redux/Reducers/MoodJournal";
import "./JournalEnteriesPrev.scss";
import { subDays } from "date-fns";
import DatePicker from "react-datepicker";
import partnerLogo from "../../../assets/img/partner.svg";
import { axiosInstance } from "../../../utils/config/axiosInstance";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const JournalEnteriesPrev = ({}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [arr, setArr] = useState([]);
  let userId = sessionStorage.getItem("authUserId");
  if (userId.startsWith("auth0|")) {
    userId = userId.slice(6);
  }
  const onChange = (dates) => {
    const [start, end] = dates;
    // console.log(start, end);
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const sdate = start.toLocaleDateString("en-GB");
      const edate = end && end.toLocaleDateString("en-GB");
      setShow(false);
      fetchEntries(sdate, edate);
    }
  };
  const fetchEntries = async (startdate, enddate) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${
          process.env.REACT_APP_API_URL_DEVELOPMENT
        }/api/v1/mood/getmood?id=${userId}&startdate=${
          startdate && startdate
        }&enddate=${enddate && enddate}`
      );
      // console.log("Mood journal list response-->jenis", response);
      response.data.item.forEach((element) => {
        const date = new Date(element.createdAt);
        element.newDate = date.toLocaleDateString("en-US", {
          dateStyle: "medium",
        });
      });
      response.data.item.forEach((element, index) => {
        const days = subDays(new Date(element?.createdAt), 0);
        arr[index] = days;
      });
      setData(response.data.item);
      dispatch(setEntries(response.data.item));
      setLoading(false);
    } catch (error) {
      // console.log("Mood journal list error", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchEntries("", "");
  }, []);

  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) { // 27 represents the escape key
        setShow(false);
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="commonWrapper">
          <div className="d-flex justify-content-between">
            <Link to="/mood-journal">
              <h3>
                <img className="img-fluid me-2" src={FaAngleLeft} />
                Journal entries
              </h3>
            </Link>
            <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Filters</Tooltip>}
                    >
            <div className="circle-btn" onClick={() => setShow(!show)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 21V15H13V17H21V19H13V21H11ZM3 19V17H9V19H3ZM7 15V13H3V11H7V9H9V15H7ZM11 13V11H21V13H11ZM15 9V3H17V5H21V7H17V9H15ZM3 7V5H13V7H3Z"
                  fill="#3484C9"
                />
              </svg>
            </div>
            </OverlayTrigger>
            {show && (
               <div ref={datePickerRef}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                highlightDates={[
                  { "react-datepicker__day--highlighted-custom-x": [...arr] },
                ]}
                selectsRange
                inline
              />
              </div>
            )}
          </div>
          {startDate && endDate
            ? `Entries are showing from ${startDate?.toLocaleDateString(
                "en-US",
                {
                  dateStyle: "medium",
                }
              )} to ${endDate?.toLocaleDateString("en-US", {
                dateStyle: "medium",
              })}`
            : ""}
          <hr />

          <ul className="accountSub mt-40">
            {data.length != 0 ? (
              data?.map((item, index) => (
                <li key={index}>
                  <Link
                    to="/single-journal-entry"
                    className="journal-link"
                    onClick={() => dispatch(setID(index))}
                  >
                    <div className="d-flex justify-content-between w-100 align-items-center me-3">
                      <div className="left-info">
                        <p>{item?.message}</p>
                        <small>{item?.newDate}</small>
                      </div>
                      <div className="right-icon">
                        <img
                          src={`${process.env.REACT_APP_API_URL_DEVELOPMENT}${item?.moodsss?.avatar}`}
                        />
                      </div>
                    </div>
                    <img src={FaAngleLeft} className="rotate" />
                  </Link>
                </li>
              ))
            ) : (
              <div>No data found</div>
            )}
          </ul>
          <div className="text-center partnerWith">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default JournalEnteriesPrev;
